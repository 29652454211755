import { FormikErrors } from 'formik';
import { object, string, InferType } from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { FunctionComponent } from 'react';

export const forgotSchema = () =>
  object()
    .shape({
      email: string()
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'อีเมลต้องมี @ และ .')
        .required('กรุณากรอกอีเมลของคุณ')
        .defined()
    })
    .defined();

export type ForgotFormValues = InferType<ReturnType<typeof forgotSchema>>;

type ForgotFormFieldProps = {
  errors: FormikErrors<ForgotFormValues>;
  values: ForgotFormValues;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  handleSubmit: (e: any) => void;
  isError: boolean;
  isSubmitting: boolean;
};

const ForgotFormField: FunctionComponent<ForgotFormFieldProps> = (props) => {
  const { errors, values, handleChange, handleBlur, handleSubmit, isError, isSubmitting } = props;
  return (
    <Form noValidate onSubmit={handleSubmit} className="forgot-form">
      <Form.Group>
        <Form.Control
          id="forget-password-input"
          type="text"
          placeholder="อีเมล"
          name="email"
          value={values.email}
          onChange={handleChange}
          isInvalid={!!errors.email}
          onBlur={handleBlur}
          autoComplete="off"
          onKeyPress={(e: any) => {
            const code = e.which ? e.which : e.keyCode;
            if (code == 32) {
              e.preventDefault();
            }
          }}
        />
        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
      </Form.Group>
      <Button
        id="forget-password-submit"
        type="submit"
        block
        variant={isError ? 'danger' : 'primary'}
        disabled={isSubmitting || isError ? true : false || Object.keys(errors).length > 0}
        className={isSubmitting ? 'submiting' : ''}
      >
        {isSubmitting ? (
          <img src="/assets/shares/loading.svg" style={{ width: 38 }} />
        ) : isError ? (
          'โปรดลองใหม่อีกครั้ง'
        ) : (
          'ถัดไป'
        )}
      </Button>
    </Form>
  );
};

export default ForgotFormField;
