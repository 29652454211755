import { PureComponent } from 'react';
import { navigate } from 'gatsby';
import axios from 'axios';
import loadable from '@loadable/component';

import { ContextType } from '@ui/components/context';
import { withTrans } from '@shares/locales/hoc';
import { onClickLogout } from '@ui/components/context/auth';
import { UserProfileBoxProps } from '../../components/profile/interface';
import { UserProfileBookingProps } from '../../components/booking';
import { UserProfileSecurityProps } from '../../components/security';
import LoadingBox from '@shares/loading';
import { AlertBox, AlertType, VerifyBox } from '@shares/alert-box';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Tab from 'react-bootstrap/Tab';
import ListGroup from 'react-bootstrap/ListGroup';

import { WEB_LEGACY_LINK, DRIVEHUB_API } from '@config';
import { appendParamsCurrentPage } from '@utils';
import { trackUserSideMenu } from './tracking';

import { SectionUserProps, SectionUserState, UserSection } from './interface';
import './style.scss';

const UserProfile = loadable<UserProfileBoxProps>(() => import(`@pages/auth/components/profile`), {
  fallback: <LoadingBox />
});
const UserProfileBooking = loadable<UserProfileBookingProps>(() => import(`@pages/auth/components/booking`), {
  fallback: <LoadingBox />
});
const UserProfileSecurity = loadable<UserProfileSecurityProps>(() => import(`@pages/auth/components/security`), {
  fallback: <LoadingBox />
});
const UserProfileSetPassword = loadable(() => import('@pages/auth/components/security/set-password'), {
  fallback: <LoadingBox />
});
const UserProfileSocialNetworks = loadable(() => import(`@pages/auth/components/social-networks`), {
  fallback: <LoadingBox />
});
const UserNotification = loadable(() => import(`@pages/auth/components/notifications`), {
  fallback: <LoadingBox />
});

class SectionUser extends PureComponent<SectionUserProps, SectionUserState> {
  state = {
    whichSection: this.props.whichSection,
    isFormAlert: false,
    verifyAlert: false,
    isResendEmail: false,
    formAlertType: undefined,
    alertMassage: undefined
  };

  static contextType = ContextType;

  componentDidUpdate() {
    const { auth } = this.context;
    if (auth.fetching) return;
    if (!auth.isAuth) {
      window.location.href = appendParamsCurrentPage(WEB_LEGACY_LINK);
    }

    if (!auth.consumer.isVerified && !this.state.isResendEmail) {
      this.setState({ verifyAlert: true });
    }
  }

  toggleAlertBox = (errorType: AlertType, massage?: string) => {
    if (errorType === AlertType.Success) {
      this.context.refreshAuth();
    }
    this.setState({ isFormAlert: true, formAlertType: errorType, alertMassage: massage }, () => {
      setTimeout(
        () =>
          this.setState({
            isFormAlert: false,
            formAlertType: this.state.formAlertType
          }),
        4000
      );
    });
  };

  handleVerifyOnClick = () => {
    const { accessToken, auth } = this.context;
    axios
      .post(
        `${DRIVEHUB_API}/v1/consumer/resend_verification_email`,
        {},
        {
          headers: {
            Authorization: accessToken
          }
        }
      )
      .then(() => {
        setTimeout(() => this.setState({ verifyAlert: false, isResendEmail: true }), 500);
        this.toggleAlertBox(AlertType.Success, `ส่งอีเมลสำเร็จ กรุณายืนยันอีเมลได้ที่ ${auth.consumer.email}`);
      })
      .catch((e) => {
        this.toggleAlertBox(AlertType.Error);
        console.log(e.response.status);
      });
  };

  renderUserComponent = () => {
    const { prefix } = this.props;
    const { isPasswordSet } = this.context.auth.consumer;

    switch (this.state.whichSection) {
      case UserSection.Profile:
        return (
          <UserProfile
            prefix={prefix}
            toggleAlertBox={this.toggleAlertBox}
            isError={this.state.formAlertType === AlertType.Error && this.state.isFormAlert === true ? true : false}
          />
        );
      case UserSection.Booking:
        return (
          <UserProfileBooking
            prefix={prefix}
            toggleAlertBox={this.toggleAlertBox}
            isError={this.state.formAlertType === AlertType.Error && this.state.isFormAlert === true ? true : false}
          />
        );
      case UserSection.Security:
        if (!isPasswordSet) {
          return (
            <UserProfileSetPassword
              prefix={prefix}
              toggleAlertBox={this.toggleAlertBox}
              isError={this.state.formAlertType === AlertType.Error && this.state.isFormAlert === true ? true : false}
            />
          );
        }
        return (
          <UserProfileSecurity
            prefix={prefix}
            toggleAlertBox={this.toggleAlertBox}
            isError={this.state.formAlertType === AlertType.Error && this.state.isFormAlert === true ? true : false}
          />
        );
      case UserSection.Notification:
        return <UserNotification prefix={prefix} toggleAlertBox={this.toggleAlertBox} />;
      case UserSection.Social:
        return <UserProfileSocialNetworks toggleAlertBox={this.toggleAlertBox} />;
      default:
        return <LoadingBox />;
    }
  };

  render() {
    return (
      <Tab.Container>
        <Container className="user-box">
          <Row>
            <Col lg={3} className="user-menu d-none d-lg-block px-0 px-lg-3">
              <ListGroup>
                <ListGroup.Item
                  id="menu-user-profile"
                  action
                  onClick={() => {
                    trackUserSideMenu('แก้ไขข้อมูลส่วนตัว');
                    navigate(appendParamsCurrentPage(`/${this.props.prefix}/user/profile`));
                  }}
                  className={this.props.whichSection === 'profile' ? 'active' : ''}
                >
                  <i className="icon-auth" />
                  แก้ไขข้อมูลส่วนตัว
                </ListGroup.Item>
                <ListGroup.Item
                  id="menu-user-password"
                  action
                  onClick={() => {
                    trackUserSideMenu('แก้ไขรหัสผ่าน');
                    navigate(appendParamsCurrentPage(`/${this.props.prefix}/user/change-password`));
                  }}
                  className={this.props.whichSection === 'security' ? 'active' : ''}
                >
                  <i className="icon-key" />
                  แก้ไขรหัสผ่าน
                </ListGroup.Item>
                <ListGroup.Item
                  id="menu-user-booking"
                  action
                  onClick={() => {
                    trackUserSideMenu('การเช่ารถของฉัน');
                    navigate(appendParamsCurrentPage(`/${this.props.prefix}/user/booking/`));
                  }}
                  className={this.props.whichSection === 'booking' ? 'active' : ''}
                >
                  <i className="icon-doc-text-inv" />
                  การเช่ารถของฉัน
                </ListGroup.Item>

                <ListGroup.Item
                  id="menu-user-social-networks"
                  action
                  onClick={() => {
                    trackUserSideMenu('การเชื่อมต่อ');
                    navigate(appendParamsCurrentPage(`/${this.props.prefix}/user/social-networks`));
                  }}
                  className={this.props.whichSection === 'social-networks' ? 'active' : ''}
                >
                  <i className="icon-chain" />
                  การเชื่อมต่อ
                </ListGroup.Item>
                <ListGroup.Item
                  id="menu-user-social-networks"
                  action
                  onClick={() => {
                    trackUserSideMenu('การแจ้งเตือน');
                    navigate(appendParamsCurrentPage(`/${this.props.prefix}/user/notifications`));
                  }}
                  className={this.props.whichSection === UserSection.Notification ? 'active' : ''}
                >
                  <i className="icon-bell" />
                  การแจ้งเตือน
                </ListGroup.Item>
                <ListGroup.Item
                  id="menu-user-logout"
                  action
                  onClick={() => {
                    trackUserSideMenu('ออกจากระบบ');
                    onClickLogout();
                  }}
                >
                  <i className="icon-logout" />
                  ออกจากระบบ
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col lg={9} className="px-0 py-0 px-lg-3 py-lg-0">
              <AlertBox
                visible={this.state.isFormAlert}
                type={this.state.formAlertType}
                customText={this.state.alertMassage}
              />
              <VerifyBox visible={this.state.verifyAlert} type={AlertType.Warning} onClick={this.handleVerifyOnClick} />
              <Tab.Content className="user-component p-lg-4">
                <Tab.Pane active>{this.renderUserComponent()}</Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Container>
      </Tab.Container>
    );
  }
}

export default withTrans('authentication.user')(SectionUser);
