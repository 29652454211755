import { CSSProperties } from 'react';
import { ConsumerInfo } from '@ui/components/context/auth';
import { authSocialDisconnectFacebook, authSocialDisconnectGoogle } from '@ui/components/context/auth';
import moment from 'moment';
import { FACEBOOK_ID, FACEBOOK_OAUTH, GOOGLE_ID, GOOGLE_OAUTH } from '@config';
import { readCookie } from '@utils';

export const getAction = (type: string) => {
  switch (type) {
    case 'facebook':
      return {
        getLink: (state: any) => {
          const str = JSON.stringify({
            ...state,
            origin: window.location.origin,
            current_url: window.location.origin + window.location.pathname,
            _ga: readCookie('_ga')
          });
          return `https://www.facebook.com/v7.0/dialog/oauth?client_id=${FACEBOOK_ID}&redirect_uri=${FACEBOOK_OAUTH}&state=${str}&auth_type=rerequest&scope=email`;
        },
        // eslint-disable-next-line react/display-name
        renderDisplay: (consumer: ConsumerInfo) => <FacebookDisplay {...consumer} />,
        disconnect: () => authSocialDisconnectFacebook()
      };
    case 'google':
      return {
        getLink: (state: any) => {
          const str = JSON.stringify({
            ...state,
            origin: window.location.origin,
            current_url: window.location.origin + window.location.pathname,
            _ga: readCookie('_ga')
          });
          return `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GOOGLE_ID}&redirect_uri=${GOOGLE_OAUTH}&scope=profile email&access_type=offline&include_granted_scopes=true&response_type=code&state=${str}`;
        },
        // eslint-disable-next-line react/display-name
        renderDisplay: (consumer: ConsumerInfo) => <GoogleDisplay {...consumer} />,
        disconnect: () => authSocialDisconnectGoogle()
      };
    default:
      return {
        getLink: (state: any) => JSON.stringify(state),
        // eslint-disable-next-line react/display-name
        renderDisplay: (consumer: ConsumerInfo) => (
          <div>
            {consumer.firstName} {consumer.lastName}
          </div>
        ),
        disconnect: () => {
          /**/
        }
      };
  }
};

const ellipsisStyle: CSSProperties = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  width: 130
};

const FacebookDisplay = (consumer: ConsumerInfo) => {
  const isConnected = consumer.facebookProfile && consumer.facebookProfile.id != '';
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: 52 }}>
      <div style={{ marginRight: 8 }}>
        <img src="/assets/shares/facebook_icon.svg" width="35" height="35" />
      </div>

      {isConnected && consumer.facebookProfile ? (
        <div>
          <div style={ellipsisStyle}>
            <b style={{ fontSize: 13 }}>
              {consumer.facebookProfile.firstName} {consumer.facebookProfile.lastName}
            </b>
          </div>
          <div style={{ fontSize: 12, color: '#6f6f6f', fontStyle: 'italic' }}>
            <div style={{ height: 19, ...ellipsisStyle }}>
              <b style={{ fontSize: 13 }}>{consumer.facebookProfile.email}</b>
            </div>
            {moment(consumer.facebookProfile.connectedAt).fromNow()}
          </div>
        </div>
      ) : (
        <b>Facebook </b>
      )}
    </div>
  );
};

const GoogleDisplay = (consumer: ConsumerInfo) => {
  const isConnected = consumer.googleProfile && consumer.googleProfile.id != '';
  return (
    <div style={{ display: 'flex', alignItems: 'center', height: 52, width: '100%' }}>
      <div style={{ marginRight: 8 }}>
        <img src="/assets/shares/google_icon.svg" width="35" height="35" />
      </div>
      {isConnected && consumer.googleProfile ? (
        <div>
          <div style={ellipsisStyle}>
            <b style={{ fontSize: 13 }}>
              {consumer.googleProfile.givenName} {consumer.googleProfile.familyName}
            </b>
          </div>
          <div style={{ fontSize: 12, color: '#6f6f6f', fontStyle: 'italic' }}>
            <div style={{ height: 19, ...ellipsisStyle }}>
              <b style={{ fontSize: 13 }}>{consumer.googleProfile.email}</b>
            </div>
            {moment(consumer.googleProfile.connectedAt).fromNow()}
          </div>
        </div>
      ) : (
        <b>Google </b>
      )}
    </div>
  );
};
