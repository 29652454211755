import { sendToDataLayer, getDefaultEventCategory, PageSection } from '@utils';

export enum SocialButton {
  Google = 'by_google',
  Facebook = 'by_facebook'
}

export enum ConnectStatus {
  Connect = 'connect',
  Disconnect = 'unconnect'
}

export const trackConnectionBtn = (whichChannel: string, whichAction: ConnectStatus) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.User),
    event_action: `${whichAction}`,
    event_label: `${whichChannel}`
  });
};
