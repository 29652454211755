import { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { withTrans } from '@shares/locales/hoc';
import LoadingBox from '@shares/loading';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import './style.scss';
import axios from 'axios';
import { setCookie } from '@utils';

const VerificationPage = () => {
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const token = query.get('verify_token');
    const consumerID = query.get('consumer_id');
    axios
      .put(`https://${window.location.host}/api/v1/consumers/${consumerID}/verify`, {
        verify_token: token
      })
      .then(({ data }) => {
        setLoading(false);
        setCookie('_consumerID', consumerID, 30, '/');
        setCookie('_consumerToken', data.token, 30, '/');
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        console.log(err.response);
        if (err.response.data) {
          switch (err.response.data.error_code) {
            case 'already_verified':
              break;
            case 'verify_token_mismatch':
              break;
          }
        }
      });
  }, []);

  if (isLoading) {
    return <VerifyLoading />;
  }
  return <VerifySuccess />;
};

const VerifyLoading = () => {
  return (
    <Col lg={4} className="d-flex flex-column p-0">
      <div className="verification-box">
        <div className="p-3 pt-lg-4"></div>
        <div className="d-flex flex-column align-items-center">
          <LoadingBox />
        </div>
      </div>
    </Col>
  );
};

const VerifySuccess = () => {
  return (
    <Col lg={4} className="d-flex flex-column p-0">
      <div className="verification-box">
        <div className="p-3 pt-lg-4">
          <div className="d-flex flex-column align-items-center verification--success">
            <img src={'/assets/icons/verify-mail.svg'} />
            <h4>ยืนยันอีเมลสำเร็จ!</h4>
            <Button size="sm" className="btn--verify-success" onClick={() => navigate('/')}>
              เริ่มต้นใช้งาน
            </Button>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default withTrans('authentication.verification')(VerificationPage);
