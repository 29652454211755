import { PureComponent, Fragment, FormEvent } from 'react';
import queryString from 'query-string';
import { navigate } from 'gatsby';
import { withTrans } from '@shares/locales/hoc';
import { ContextType } from '@ui/components/context';
import { SectionRegisterProps } from './interface';
import {
  RegisterFormValues,
  registerSchema,
  RegisterFormFieldEmail,
  RegisterFormFieldPassword
} from '@pages/auth/components/register-form-field';
import NewsletterCheckBox from '@pages/auth/components/newsletter-box';
import { withFormik, FormikProps } from 'formik';
import { authValidate, authRegister, onClickLogout } from '@ui/components/context/auth';
import { readCookie, deleteCookie, appendParamsCurrentPage, setCookie } from '@utils';
import { AlertBox, AlertType } from '@shares/alert-box';
import { WEB_LEGACY_LINK } from '@config';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { GoogleButton } from '@pages/auth/components/social-networks/google-button';
import { FacebookButton } from '@pages/auth/components/social-networks/facebook-button';
import { SocialAction } from '@pages/auth/components/social-networks/interface';
import { SocialButton } from '@pages/auth/components/social-networks/tracking';
import { trackSocialEmailRegister, trackSocialRegister, trackOnClickElement, trackOnClickNewsletter } from './tracking';
import { COOKIE_HOST } from '@config';
import { canUseDOM } from '@utils';
import './style.scss';
interface SectionRegisterState {
  step: number;
  isEmailExisting: boolean;
  isFormAlert: boolean;
  formAlertType?: AlertType;
  alertMassage?: string;
  emailSource?: string;
}

const getFlashErrorMessage = (err: string) => {
  switch (err) {
    case `"pq: duplicate key value violates unique constraint index_consumers_on_email"`:
      return 'อีเมลซ้ำ';
    case 'google_already_connected':
      return 'อีเมลนี้มีอยู่ระบบแล้ว กรุณาใช้รหัสผ่านในการเข้าสู่ระบบ';
    case 'facebook_already_connected':
      return 'อีเมลนี้มีอยู่ระบบแล้ว กรุณาใช้รหัสผ่านในการเข้าสู่ระบบ';
    case 'facebook_email_not_found':
      return 'Facebook นี้ไม่ได้ลงด้วยทะเบียนด้วยอีเมล';
    default:
      return 'ระบบเกิดข้อผิดพลาด โปรดลองใหม่อีกครั้ง';
  }
};

class SectionRegister extends PureComponent<
  SectionRegisterProps & TranslationProps & FormikProps<RegisterFormValues>,
  SectionRegisterState
> {
  state = {
    step: 0,
    isEmailExisting: false,
    isFormAlert: false,
    formAlertType: undefined,
    alertMassage: undefined,
    emailSource: undefined
  };
  static contextType = ContextType;
  componentDidMount() {
    window.location.hash = this.state.step.toString();
    const flashError = readCookie('_flashError');
    console.log(flashError);

    if (flashError) {
      this.toggleAlertBox(AlertType.Error, getFlashErrorMessage(flashError));
      deleteCookie('_flashError');
      deleteCookie('_event');
      deleteCookie('_platform');
    }
  }
  componentDidUpdate(
    prevProps: SectionRegisterProps & TranslationProps & FormikProps<RegisterFormValues>,
    prevState: { step: number; isEmailExisting: boolean }
  ) {
    if (prevState.step !== this.state.step) {
      window.location.hash = this.state.step.toString();
    }
    if (prevProps.status !== this.props.status) {
      if (this.props.status.error) this.toggleAlertBox(AlertType.Error);
    }
    const { auth } = this.context;
    if (auth.isAuth) {
      if (canUseDOM()) {
        const { query } = queryString.parseUrl(window.location.href);

        if (query.utm_campaign === 'major') {
          onClickLogout(true);
          this.redirectToPath('user/login');
          return;
        }
      }
      navigate(`/`);
    }
  }

  toggleNext = () => {
    this.setState({ step: this.state.step + 1 });
  };
  togglePrevious = () => {
    // sendToGAByFreeText('account', 'back_sign_up', '');
    this.setState({ step: this.state.step - 1 });
  };
  redirectToPath = (path: string) => {
    window.location.href = appendParamsCurrentPage(`${WEB_LEGACY_LINK}/${this.props.prefix}/${path}`);
  };

  toggleAlertBox = (errorType: AlertType, massage?: string) => {
    this.setState({ isFormAlert: true, formAlertType: errorType, alertMassage: massage }, () => {
      setTimeout(() => this.setState({ isFormAlert: false }), 4000);
    });
  };

  checkIsEmailExisting = (email: string) => {
    authValidate('email', email)
      .then(({ valid, source }) => {
        if (valid) {
          this.setState({ isEmailExisting: false, emailSource: source });

          return this.toggleNext();
        }
        return this.setState({ isEmailExisting: true, emailSource: source });
      })
      .catch(() => {
        return this.toggleAlertBox(AlertType.Error);
      });
  };

  handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
    // NOTE: Fix it to use yup only.
    if (this.state.step === 2) return this.props.handleSubmit(e);
    e.preventDefault();
    if (this.props.errors.email) return;
    if (this.props.values.email === '') return this.props.setFieldError('email', 'กรุณากรอกอีเมลของคุณ');
    this.checkIsEmailExisting(this.props.values.email);
  };

  render() {
    const { prefix, setFieldValue, values } = this.props;

    return (
      <Col lg={4} className="d-flex flex-column p-0">
        <AlertBox
          visible={this.state.isFormAlert}
          type={this.state.formAlertType}
          customText={this.state.alertMassage}
        />
        <div className="register-box p-3 pt-lg-4">
          {this.state.step !== 0 && (
            <p id="sign-up-back" className="register-back-btn" onClick={this.togglePrevious}>
              <i className="icon-left" />
              ย้อนกลับ
            </p>
          )}
          {this.state.step === 0 ? (
            <Fragment>
              <h3>ลงชื่อสมัครใช้</h3>
              <button
                id="sign-up-email"
                className="btn btn-block btn-primary"
                onClick={() => {
                  trackSocialEmailRegister();
                  this.toggleNext();
                }}
              >
                สมัครสมาชิกด้วยอีเมล{' '}
              </button>
              <div className="spacer">หรือ</div>
              <GoogleButton
                redirectPath="/th/user/profile"
                event={SocialAction.Register}
                isAcceptedNewsletter={values.isAcceptedNewsletter}
                onTracking={() => trackSocialRegister(SocialButton.Google)}
              />
              <FacebookButton
                redirectPath="/th/user/profile"
                event={SocialAction.Register}
                isAcceptedNewsletter={values.isAcceptedNewsletter}
                onTracking={() => trackSocialRegister(SocialButton.Facebook)}
              />
              <NewsletterCheckBox
                isAccepted={values.isAcceptedNewsletter}
                onChange={() => {
                  setFieldValue('isAcceptedNewsletter', !values.isAcceptedNewsletter);
                  trackOnClickNewsletter(!values.isAcceptedNewsletter);
                }}
              />
              <div
                id="sign-up-to-sign-in"
                className="register-login text-center"
                onClick={() => {
                  trackOnClickElement('login');
                  window.location.href = `${WEB_LEGACY_LINK}/${prefix}/user/login`;
                }}
              >
                หากคุณเป็นสมาชิกอยู่แล้ว <span>เข้าสู่ระบบ</span>
              </div>
              <div className="register-term text-center">
                ในการสมัครสมาชิก ฉันยอมรับ
                <span
                  id="account-to-terms"
                  onClick={() => {
                    trackOnClickElement('terms');
                    this.redirectToPath('terms');
                  }}
                >
                  ข้อกำหนดและเงื่อนไข
                </span>{' '}
                และ
                <span
                  id="account-to-privacy"
                  onClick={() => {
                    trackOnClickElement('privcay');
                    this.redirectToPath('privacy');
                  }}
                >
                  นโยบายความเป็นส่วนตัว
                </span>
                ของ Drivehub
              </div>
            </Fragment>
          ) : (
            <Form onSubmit={(e: FormEvent<HTMLFormElement>) => this.handleFormSubmit(e)} className="register-form">
              {this.state.step === 1 && (
                <>
                  <RegisterFormFieldEmail
                    prefix={prefix}
                    isEmailExisting={this.state.isEmailExisting}
                    errors={this.props.errors}
                    values={this.props.values}
                    handleChange={this.props.handleChange}
                    handleBlur={this.props.handleBlur}
                    onChange={() => {
                      if (this.state.isEmailExisting) {
                        this.setState({ isEmailExisting: false, emailSource: undefined });
                      }
                    }}
                    emailSource={this.state.emailSource}
                  />
                  <Button id="sign-up-email-submit" block type="submit">
                    ถัดไป
                  </Button>
                </>
              )}
              {this.state.step === 2 && (
                <>
                  <RegisterFormFieldPassword
                    errors={this.props.errors}
                    values={this.props.values}
                    handleChange={(e) => {
                      if (this.props.values.password === '') this.props.setErrors({});
                      return this.props.handleChange(e);
                    }}
                    handleBlur={() => {
                      return;
                    }}
                  />
                  <Button
                    id="sign-up-password-submit"
                    type="submit"
                    block
                    disabled={this.props.isSubmitting || Object.keys(this.props.errors).length > 0 ? true : false}
                    variant={this.state.isFormAlert ? 'danger' : 'primary'}
                  >
                    {this.props.isSubmitting ? (
                      <img src="/assets/shares/loading.svg" className="loading--register" />
                    ) : this.state.isFormAlert ? (
                      'โปรดลองใหม่อีกครั้ง'
                    ) : (
                      'สมัครสมาชิก'
                    )}
                  </Button>
                </>
              )}
            </Form>
          )}
        </div>
      </Col>
    );
  }
}

const RegisterForm = withFormik<SectionRegisterProps, RegisterFormValues>({
  mapPropsToValues: () => ({
    email: '',
    password: '',
    isAcceptedNewsletter: true
  }),
  validationSchema: registerSchema(),
  handleSubmit: (values, formikBag) => {
    if (values.password === undefined || values.password === '')
      return formikBag.setFieldError('password', 'กรุณากรอกรหัสผ่านของคุณ');
    authRegister(values.email.toLowerCase(), values.password, values.isAcceptedNewsletter)
      .then(async () => {
        formikBag.setStatus({});
        const originUrl = readCookie('_where');
        await deleteCookie('_where');
        await setCookie('_socialEventIntention', `${SocialAction.Register}`, 1, '/', COOKIE_HOST);
        await setCookie('_socialEventActual', `${SocialAction.Register}`, 1, '/', COOKIE_HOST);
        await setCookie('_socialChannel', `email`, 1, '/', COOKIE_HOST);
        window.location.href = appendParamsCurrentPage(originUrl ? originUrl : `/`);
      })
      .catch((e) => {
        formikBag.setSubmitting(false);
        formikBag.setStatus({ error: e.status });
        console.log(e.data);
      });
  },
  enableReinitialize: false
})(withTrans('auth.register')(SectionRegister));

export default RegisterForm;
