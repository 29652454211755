import { useState, useEffect, FunctionComponent } from 'react';
import { FormikErrors } from 'formik';
import { object, string, InferType } from 'yup';
import { trackLoginBtn } from './tracking';
import { trackLoginSubmit, LoginStatus } from '../../section/login/tracking';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const textError = 'รหัสผ่านต้องมีอักขระ 8 ตัวขึ้นไป โดยใช้ตัวอักษร หรือตัวเลข';

export const loginSchema = () =>
  object()
    .shape({
      email: string()
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'อีเมลต้องมี @ และ .')
        .required('กรุณากรอกอีเมลของคุณ')
        .defined(),
      password: string()
        .min(8, textError)
        .matches(/^(?:(?=.*[a-z])|(?=.*\d)).{8,}$/, textError)
        .required('กรุณากรอกรหัสผ่านของคุณ')
        .defined()
    })
    .defined();

type LoginFormFieldProps = {
  errors: FormikErrors<LoginFormValues>;
  values: LoginFormValues;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  handleSubmit: (e: any) => void;
  isSubmitting: boolean;
  isFormAlert: boolean;
};
export type LoginFormValues = InferType<ReturnType<typeof loginSchema>>;

const LoginFormField: FunctionComponent<LoginFormFieldProps> = (props) => {
  const { errors, values, handleChange, handleBlur, handleSubmit, isSubmitting, isFormAlert } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);

  useEffect(() => {
    const { email, password } = props.errors;
    if (email || password) {
      trackLoginSubmit(LoginStatus.Fail, props.errors);
    }
  }, [props.isSubmitting]);

  return (
    <Form noValidate onSubmit={handleSubmit} className="login-form">
      <Form.Group>
        <Form.Control
          id="sign-in-email-input"
          type="text"
          placeholder="อีเมล"
          name="email"
          value={values.email}
          onChange={handleChange}
          isInvalid={!!errors.email}
          onBlur={handleBlur}
          autoComplete="off"
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            const code = e.which ? e.which : e.keyCode;
            if (code == 32) {
              e.preventDefault();
            }
          }}
        />
        <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="position-relative phone-input">
        <Form.Control
          id="sign-in-password-input"
          type={`${showPassword ? 'text' : 'password'}`}
          placeholder="รหัสผ่าน"
          name="password"
          value={values.password}
          onChange={handleChange}
          isInvalid={!!errors.password}
          onBlur={handleBlur}
          autoComplete="off"
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            const code = e.which ? e.which : e.keyCode;
            if (code == 32) {
              e.preventDefault();
            }
          }}
        />
        <i className={`${showPassword ? 'icon-eye' : 'icon-eye-off'}`} onClick={() => setShowPassword(!showPassword)} />
        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
      </Form.Group>
      <Button
        id="sign-in-email-submit"
        type="submit"
        block
        disabled={isSubmitting || Object.keys(props.errors).length > 0 ? true : false}
        variant={isFormAlert ? 'danger' : 'primary'}
        onClick={trackLoginBtn}
      >
        {isSubmitting ? (
          <img src="/assets/shares/loading.svg" className="loading--login" />
        ) : isFormAlert ? (
          'โปรดลองใหม่อีกครั้ง'
        ) : (
          'เข้าสู่ระบบ'
        )}
      </Button>
    </Form>
  );
};

export default LoginFormField;
