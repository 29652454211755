import { FunctionComponent } from 'react';
import FormCheck from 'react-bootstrap/FormCheck';
import './style.scss';
interface NewsletterCheckBoxProps {
  onChange: () => void;
  isAccepted?: boolean;
}
const NewsletterCheckBox: FunctionComponent<NewsletterCheckBoxProps> = ({ isAccepted, onChange }) => {
  return (
    <div className="newsletter-box">
      <FormCheck>
        <FormCheck.Input id="newsletter" name="newsletter" onChange={onChange} checked={isAccepted} />
        <FormCheck.Label htmlFor="newsletter">
          ฉันต้องการรับการแจ้งเตือนข่าวสารและสิทธิประโยชน์ จาก Drivehub ผ่านอีเมล, SMS, แอปพลิเคชั่นและสื่อออนไลน์ต่าง ๆ
        </FormCheck.Label>
      </FormCheck>
    </div>
  );
};

export default NewsletterCheckBox;
