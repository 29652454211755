import { PureComponent } from 'react';
import Layout from '@shares/layout';
import SEO from '@shares/seo';
import Context, { ContextType } from '@ui/components/context';
import { withTrans } from '@shares/locales/hoc';
import SectionLogin from '@pages/auth/section/login';
import SectionRegister from '@pages/auth/section/register';
import SectionUser from '@pages/auth/section/user';
import SectionVerification from '@pages/auth/section/verification';
import { UserSection } from '@pages/auth/section/user/interface';
import SectionForgot from '@pages/auth/section/forgot';
import SectionForgotReset from '@pages/auth/section/forgot/reset-box';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { PageSection } from '@utils';

import { AuthenticationProps, AuthenticationState } from './interface';
import './style.scss';

class AuthenticationPage extends PureComponent<AuthenticationProps, AuthenticationState> {
  constructor(props: AuthenticationProps) {
    super(props);
    this.state = {
      whichSection: this.props.pageContext.urlPath || 'login'
    };
  }
  static contextType = ContextType;

  renderAuthSection = (section: string) => {
    const { prefix } = this.props.pageContext;

    switch (section) {
      case 'register':
        return <SectionRegister prefix={prefix} />;
      case UserSection.Profile:
        return <SectionUser prefix={prefix} whichSection={UserSection.Profile} />;
      case 'change-password':
        return <SectionUser prefix={prefix} whichSection={UserSection.Security} />;
      case UserSection.Booking:
        return <SectionUser prefix={prefix} whichSection={UserSection.Booking} />;
      case UserSection.Social:
        return <SectionUser prefix={prefix} whichSection={UserSection.Social} />;
      case UserSection.Notification:
        return <SectionUser prefix={prefix} whichSection={UserSection.Notification} />;
      case 'forgot':
        return <SectionForgot prefix={prefix} />;
      case 'reset':
        return <SectionForgotReset prefix={prefix} />;
      case 'verification':
        return <SectionVerification prefix={prefix} />;
      default:
        return <SectionLogin prefix={prefix} />;
    }
  };

  render() {
    const { prefix } = this.props.pageContext;

    return (
      <Context>
        <Layout prefix={prefix} section={PageSection.User} isTransparent={false}>
          <SEO
            prefix={prefix}
            title={'Drivehub ค้นหารถเช่า เช่ารถ ราคาถูกที่สุด จากบริษัทเช่ารถทั่วประเทศ'}
            description={
              'จะต้องเสียเวลาในการค้นหารถเช่าอยู่ทำไม Drivehub ผู้ให้บริการค้นหาบริษัทเช่ารถ รายวัน ราคาถูกในปี 2020 มีรถหลากแบบทั้ง รถตู้ รถ SUV รถ Eco ไว้ในที่เดียว'
            }
            keywords={'บริการ เช่ารถ, รถเช่า, ราคาถูก, รายวัน, ขับเอง, รถตู้, รถ suv, รถ eco car'}
            isHideReturningPopup
            disableAppBanner
          />
          <div
            className={`authentication-box ${
              this.state.whichSection === 'register' || this.state.whichSection === 'login' ? 'auth-bg' : ''
            }`}
          >
            <Container className="authentication">
              <Row className="justify-content-center">{this.renderAuthSection(this.state.whichSection)}</Row>
            </Container>
          </div>
        </Layout>
      </Context>
    );
  }
}

export default withTrans()(AuthenticationPage);
