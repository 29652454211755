import { AlertType } from '@shares/alert-box';

export enum UserSection {
  Profile = 'profile',
  Security = 'security',
  Booking = 'booking',
  Social = 'social-networks',
  Notification = 'notifications'
}
export interface SectionUserProps {
  prefix: string;
  whichSection: UserSection;
}

export interface SectionUserState {
  whichSection: UserSection;
  isFormAlert: boolean;
  verifyAlert: boolean;
  isResendEmail: boolean;
  formAlertType?: AlertType;
  alertMassage?: string;
}
