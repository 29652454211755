import { PureComponent } from 'react';
import { navigate } from 'gatsby';
import { withTrans } from '@shares/locales/hoc';
import { withFormik, FormikProps } from 'formik';
import ForgotFormField, { forgotSchema, ForgotFormValues } from '@pages/auth/components/forgot-form-field';
import { authValidate, authForgotPassword } from '@ui/components/context/auth';
import Col from 'react-bootstrap/Col';
import { AlertBox, AlertType } from '@shares/alert-box';
import './style.scss';

interface SectionForgotProps {
  prefix: string;
}

interface SectionForgotState {
  step: string;
  isFormAlert: boolean;
  formAlertType?: AlertType;
  alertMassage?: string;
}

class SectionForgot extends PureComponent<
  SectionForgotProps & TranslationProps & FormikProps<ForgotFormValues>,
  SectionForgotState
> {
  state = {
    step: '#0',
    isFormAlert: false,
    formAlertType: undefined,
    alertMassage: undefined
  };

  componentDidMount() {
    if (window.location.hash !== '' && window.location.hash === '#token-error') {
      setTimeout(
        () => this.toggleAlertBox(AlertType.Error, 'ลิงก์รีเซ็ตรหัสผ่านของคุณหมดอายุ หรือมีการใช้งานไปแล้ว', false),
        700
      );
      return this.setState({ step: window.location.hash });
    }
  }

  componentDidUpdate(prevProps: SectionForgotProps & TranslationProps & FormikProps<ForgotFormValues>) {
    if (prevProps.status !== this.props.status) {
      if (this.props.status.error) this.toggleAlertBox(AlertType.Error, this.props.status.massage);
    }
  }

  toggleAlertBox = (errorType: AlertType, massage?: string, autoHide?: boolean) => {
    if (!autoHide)
      return this.setState({
        isFormAlert: true,
        formAlertType: errorType,
        alertMassage: massage
      });
    this.setState({ isFormAlert: true, formAlertType: errorType, alertMassage: massage }, () => {
      setTimeout(() => this.setState({ isFormAlert: false }), 4000);
    });
  };

  render() {
    return (
      <Col lg={4} className="d-flex flex-column p-0">
        <AlertBox
          visible={this.state.isFormAlert}
          type={this.state.formAlertType}
          customText={this.state.alertMassage}
        />
        <div className="forgot-box p-3 pt-lg-4">
          {this.props.status === 'success' ? (
            <div className="success-box d-flex px-4">
              <img src="/assets/shares/forgot-sent.svg" style={{ width: 120, marginBottom: '1rem' }} />
              <h3>โปรดเช็คอีเมลของคุณ</h3>
              <p className="text-center">
                เราได้ส่งลิงก์รีเซ็ตรหัสผ่านไปที่อีเมล{' '}
                <span className="font-weight-bold">{this.props.values.email}</span> แล้ว
              </p>
            </div>
          ) : (
            <>
              <p className="back-btn" onClick={() => navigate(`/${this.props.prefix}/user/login`)}>
                <i className="icon-left" />
                ย้อนกลับ
              </p>
              <h3>ลืมรหัสผ่าน</h3>
              <ForgotFormField
                isError={this.state.isFormAlert}
                errors={this.props.errors}
                values={this.props.values}
                handleChange={(e) => {
                  if (this.state.isFormAlert) {
                    window.location.hash = '#0';
                    this.setState({ isFormAlert: false, step: '#0' });
                  }
                  this.props.handleChange(e);
                }}
                handleBlur={this.props.handleBlur}
                handleSubmit={this.props.handleSubmit}
                isSubmitting={this.props.isSubmitting}
              />
            </>
          )}
        </div>
      </Col>
    );
  }
}

const ForgotForm = withFormik<SectionForgotProps, ForgotFormValues>({
  mapPropsToValues: () => ({
    email: ''
  }),
  validationSchema: forgotSchema(),
  handleSubmit: (values, formikBag) => {
    authValidate('email', values.email)
      .then(({ valid }) => {
        if (valid) {
          return formikBag.setStatus({
            error: 400,
            massage: 'ไม่พบอีเมล์ในระบบ โปรลองใหม่อีกครั้ง'
          });
        }
        formikBag.setStatus('success');
        authForgotPassword(values.email);
      })
      .catch((e) => {
        console.log(e.data);
        formikBag.setStatus({ error: e.status });
      });
    formikBag.setSubmitting(false);
  }
})(withTrans('authentication.forgot')(SectionForgot));

export default ForgotForm;
