import Button from 'react-bootstrap/Button';
import queryString from 'query-string';
import { getAction } from '../utils';
import { SocialAction } from '../interface';
import { FunctionComponent } from 'react';
import { canUseDOM } from '@utils';

const getLink = getAction('google').getLink;

const GoogleLoginButton = () => {
  const onClick = () => {
    const state = {
      type: 'register'
    };
    const link = getLink(state);
    window.location.replace(link);
  };
  return (
    <div>
      <button onClick={onClick}>GOOGLE</button>
    </div>
  );
};

type GoogleButtonProps = {
  redirectPath: string;
  event: SocialAction;
  isAcceptedNewsletter?: boolean;
  onTracking?: () => void;
};

export const GoogleButton: FunctionComponent<GoogleButtonProps> = ({
  event,
  redirectPath,
  onTracking,
  isAcceptedNewsletter = false
}) => {
  const onClick = () => {
    const state = {
      type: 'register',
      redirect_url: window.location.origin + redirectPath,
      event: event
    };
    if (isAcceptedNewsletter != undefined)
      state['is_accepted_newsletter'] = isAcceptedNewsletter === true ? 'true' : 'false';
    if (canUseDOM()) {
      const { query } = queryString.parseUrl(window.location.href);
      if (query.utm_campaign) {
        state['utm_campaign'] = query.utm_campaign;
      }
    }
    const link = getLink(state);
    onTracking && onTracking();
    window.location.replace(link);
  };
  return (
    <Button onClick={onClick} style={{ background: '#d34836', borderColor: '#d34836', position: 'relative' }} block>
      <i className="icon-google" style={{ position: 'absolute', left: '1rem' }} />
      เชื่อมต่อด้วย Google
    </Button>
  );
};

export default GoogleLoginButton;
