import Button from 'react-bootstrap/Button';
import { getAction } from '../utils';
import { SocialAction } from '../interface';
import { FunctionComponent } from 'react';
import queryString from 'query-string';
import { canUseDOM } from '@utils';

const getLink = getAction('facebook').getLink;

const FacebookLoginButton = () => {
  const onClick = () => {
    const state = {
      type: 'register'
    };
    const link = getLink(state);
    window.location.replace(link);
  };
  return (
    <div>
      <button onClick={onClick}>FACEBOOK</button>
    </div>
  );
};

type LoginPageProps = {
  redirectPath: string;
  event: SocialAction;
  isAcceptedNewsletter?: boolean;
  onTracking?: () => void;
};

export const FacebookButton: FunctionComponent<LoginPageProps> = ({
  event,
  redirectPath,
  onTracking,
  isAcceptedNewsletter = false
}) => {
  const onClick = () => {
    const state = {
      type: 'register',
      redirect_url: window.location.origin + redirectPath,
      event: event
    };

    if (isAcceptedNewsletter != undefined)
      state['is_accepted_newsletter'] = isAcceptedNewsletter === true ? 'true' : 'false';
    if (canUseDOM()) {
      const { query } = queryString.parseUrl(window.location.href);
      if (query.utm_campaign) {
        state['utm_campaign'] = query.utm_campaign;
      }
    }
    const link = getLink(state);
    onTracking && onTracking();
    window.location.replace(link);
  };
  return (
    <Button onClick={onClick} style={{ background: '#3b5998', borderColor: '#3b5998', position: 'relative' }} block>
      <i className="icon-facebook" style={{ position: 'absolute', left: '1rem' }} />
      เชื่อมต่อด้วย Facebook
    </Button>
  );
};

export default FacebookLoginButton;
