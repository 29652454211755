import { FunctionComponent, useState } from 'react';
import { FormikErrors } from 'formik';
import { object, string, InferType } from 'yup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

const textError = 'รหัสผ่านต้องมีอักขระ 8 ตัวขึ้นไป โดยใช้ตัวอักษร หรือตัวเลข';

export const resetSchema = () =>
  object()
    .shape({
      password: string()
        .required('กรุณากรอกรหัสผ่านของคุณ')
        .min(8, textError)
        .matches(/^(?:(?=.*[a-z])|(?=.*\d)).{8,}$/, textError)
        .defined(),
      email: string().defined(),
      reset_password_token: string().defined()
    })
    .defined();

export type ResetFormValues = InferType<ReturnType<typeof resetSchema>>;

type ResetFormFieldProps = {
  errors: FormikErrors<ResetFormValues>;
  values: ResetFormValues;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  handleSubmit: (e: any) => void;
  isError: boolean;
  isSubmitting: boolean;
};

const ResetFormField: FunctionComponent<ResetFormFieldProps> = (props) => {
  const { errors, values, handleChange, handleBlur, handleSubmit, isError, isSubmitting } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <Form noValidate onSubmit={handleSubmit} className="forgot-form">
      <Form.Group className="position-relative">
        <Form.Control
          id="set-new-password-input"
          type={showPassword ? 'text' : 'password'}
          placeholder="รหัสผ่าน"
          name="password"
          value={values.password}
          onChange={handleChange}
          isInvalid={!!errors.password}
          onBlur={handleBlur}
          autoComplete="off"
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            const code = e.which ? e.which : e.keyCode;
            if (code == 32) {
              e.preventDefault();
            }
          }}
        />
        <i
          className={`password-icon ${showPassword ? 'icon-eye' : 'icon-eye-off'}`}
          onClick={() => setShowPassword(!showPassword)}
        />
        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
      </Form.Group>
      <Button
        id="set-new-password-submit"
        type="submit"
        block
        variant={isError ? 'danger' : 'primary'}
        disabled={isSubmitting || isError ? true : false || Object.keys(errors).length > 0}
        className={isSubmitting ? 'submiting' : ''}
      >
        {isSubmitting ? (
          <img src="/assets/shares/loading.svg" style={{ width: 38 }} />
        ) : isError ? (
          'โปรดลองใหม่อีกครั้ง'
        ) : (
          'ตกลง'
        )}
      </Button>
    </Form>
  );
};

export default ResetFormField;
