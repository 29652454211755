import { PureComponent } from 'react';
import { navigate } from 'gatsby';
import { ContextType } from '@ui/components/context';
import { withTrans } from '@shares/locales/hoc';
import { Formik } from 'formik';
import { SectionLoginProps } from './interface';
import { authLogin, onClickRegister } from '@ui/components/context/auth';
import { readCookie, deleteCookie, setCookie } from '@utils';
import LoginFormField, { loginSchema } from '@pages/auth/components/login-form-field';
import { FacebookButton } from '@pages/auth/components/social-networks/facebook-button';
import { GoogleButton } from '@pages/auth/components/social-networks/google-button';
import { SocialAction } from '@pages/auth/components/social-networks/interface';
import { SocialButton } from '@pages/auth/components/social-networks/tracking';
import { AlertBox, AlertType } from '@shares/alert-box';
import Col from 'react-bootstrap/Col';
import { trackLoginSubmit, LoginStatus, trackSocialLogin, trackOnClickElement } from './tracking';
import { COOKIE_HOST } from '@config';

import './style.scss';

const getFlashErrorMessage = (err: string) => {
  switch (err) {
    case `"pq: duplicate key value violates unique constraint index_consumers_on_email"`:
      return 'อีเมลซ้ำ';
    case 'google_already_connected':
      return 'อีเมลนี้มีอยู่ระบบแล้ว กรุณาใช้รหัสผ่านในการเข้าสู่ระบบ';
    case 'facebook_already_connected':
      return 'อีเมลนี้มีอยู่ระบบแล้ว กรุณาใช้รหัสผ่านในการเข้าสู่ระบบ';
    case 'facebook_email_not_found':
      return 'Facebook นี้ไม่ได้ลงด้วยทะเบียนด้วยอีเมล';
    default:
      return 'ระบบเกิดข้อผิดพลาด โปรดลองใหม่อีกครั้ง';
  }
};

class SectionLogin extends PureComponent<
  SectionLoginProps,
  { isFormAlert: boolean; formAlertType?: AlertType; alertMassage?: string }
> {
  state = {
    isFormAlert: false,
    formAlertType: undefined,
    alertMassage: undefined
  };
  static contextType = ContextType;
  componentDidMount() {
    const flashError = readCookie('_flashError');
    console.log(flashError);

    if (flashError) {
      this.toggleAlertBox(AlertType.Error, getFlashErrorMessage(flashError));
      deleteCookie('_flashError');
      deleteCookie('_event');
      deleteCookie('_platform');
    }
  }
  componentDidUpdate() {
    const { auth } = this.context;
    if (auth.isAuth) {
      navigate(`/`);
    }
  }

  toggleAlertBox = (errorType: AlertType, massage?: string) => {
    this.setState({ isFormAlert: true, formAlertType: errorType, alertMassage: massage }, () => {
      setTimeout(() => this.setState({ isFormAlert: false }), 4000);
    });
  };

  render() {
    return (
      <Col lg={4} className="d-flex flex-column p-0">
        <AlertBox
          visible={this.state.isFormAlert}
          type={this.state.formAlertType}
          customText={this.state.alertMassage}
        />
        <div className="login-box p-3 pt-lg-4">
          <h3>เข้าสู่ระบบ</h3>
          <Formik
            enableReinitialize={false}
            validationSchema={loginSchema()}
            onSubmit={async (values, actions) => {
              await authLogin(values.email, values.password)
                .then(async () => {
                  const originUrl = readCookie('_where') || '/';
                  await deleteCookie('_where');
                  await setCookie('_socialEventIntention ', `${SocialAction.Login}`, 1, '/', COOKIE_HOST);
                  await setCookie('_socialEventActual', `${SocialAction.Login}`, 1, '/', COOKIE_HOST);
                  await setCookie('_socialChannel', `email`, 1, '/', COOKIE_HOST);

                  window.location.href = originUrl;
                })
                .catch((e) => {
                  if (e.status === 400) {
                    trackLoginSubmit(LoginStatus.Fail, {}, 'อีเมลหรือรหัสผ่านไม่ถูกต้อง');
                    this.toggleAlertBox(AlertType.Error, 'อีเมลหรือรหัสผ่านไม่ถูกต้อง');
                  } else {
                    trackLoginSubmit(LoginStatus.Fail, {}, 'api');
                    this.toggleAlertBox(AlertType.Error);
                  }
                });
              actions.setSubmitting(false);
            }}
            initialValues={{ email: '', password: '' }}
          >
            {({ handleSubmit, handleChange, errors, values, isSubmitting, setErrors }) => (
              <LoginFormField
                errors={errors}
                values={values}
                handleChange={handleChange}
                handleBlur={() => {
                  if (values.email === '' && values.password === '') setErrors({});
                  // handleBlur(e);
                }}
                handleSubmit={handleSubmit}
                isSubmitting={isSubmitting}
                isFormAlert={this.state.isFormAlert}
              />
            )}
          </Formik>
          <div className="spacer">หรือ</div>
          <GoogleButton
            redirectPath="/"
            event={SocialAction.Login}
            onTracking={() => trackSocialLogin(SocialButton.Google)}
          />
          <FacebookButton
            redirectPath="/"
            event={SocialAction.Login}
            onTracking={() => trackSocialLogin(SocialButton.Facebook)}
          />
          <div className="login-footer text-center py-4">
            <a
              className="px-4"
              href={'#0'}
              onClick={() => {
                trackOnClickElement('forgot_password');
                navigate(`/${this.props.prefix}/user/forgot`);
              }}
              id="sign-in-forget-password"
            >
              ลืมรหัสผ่าน
            </a>
            <a
              id="redirect-to-sign-up"
              className="px-4"
              href="#register"
              onClick={() => {
                trackOnClickElement('register');
                onClickRegister(this.props.prefix);
              }}
            >
              สมัครสมาชิก
            </a>
          </div>
        </div>
      </Col>
    );
  }
}

export default withTrans('authentication.login')(SectionLogin);
