import { sendToDataLayer, getDefaultEventCategory, PageSection } from '@utils';

// const PROFILE_PAGE_EVENT_CATEGORY = 'profile_section';
// export const getUserSectionCategory = (which: UserSection) => {
//   switch (which) {
//     case UserSection.Profile:
//       return PROFILE_PAGE_EVENT_CATEGORY;
//     default:
//       return 'undefined';
//   }
// };

export const trackUserSideMenu = (subMenuName: string) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(PageSection.User),
    event_action: 'click_menu',
    event_label: `${subMenuName}`
  });
};
