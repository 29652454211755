import { FunctionComponent, useState } from 'react';
import { FormikErrors } from 'formik';
import { object, string, InferType, boolean } from 'yup';
import { onClickLogin } from '@ui/components/context/auth';
import Form from 'react-bootstrap/Form';

const textError = 'รหัสผ่านต้องมีอักขระ 8 ตัวขึ้นไป โดยใช้ตัวอักษร หรือตัวเลข';

export const registerSchema = () =>
  object()
    .shape({
      email: string()
        .required('กรุณากรอกอีเมลของคุณ')
        .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'อีเมลต้องมี @ และ .')
        .defined(),
      password: string()
        .min(8, textError)
        .matches(/^(?:(?=.*[a-z])|(?=.*\d)).{8,}$/, textError),
      isAcceptedNewsletter: boolean().default(true).required()
    })
    .defined();

export type RegisterFormValues = InferType<ReturnType<typeof registerSchema>>;

type RegisterFormFieldProps = {
  errors: FormikErrors<RegisterFormValues>;
  values: RegisterFormValues;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
};

export const RegisterFormFieldEmail: FunctionComponent<
  {
    isEmailExisting: boolean;
    emailSource?: string;
    prefix: string;
    onChange: () => void;
  } & RegisterFormFieldProps
> = (props) => {
  const { errors, values, handleChange, handleBlur, isEmailExisting, prefix } = props;
  const emailSource = props.emailSource ? props.emailSource.charAt(0).toUpperCase() + props.emailSource.slice(1) : '';
  return (
    <>
      <Form.Group>
        <Form.Control
          id="sign-up-email-input"
          type="text"
          placeholder="อีเมล"
          name="email"
          value={values.email}
          onChange={(e: any) => {
            props.onChange();
            handleChange(e);
          }}
          isInvalid={!!errors.email || isEmailExisting}
          onBlur={handleBlur}
          autoComplete="off"
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            const code = e.which ? e.which : e.keyCode;
            if (code == 32) {
              e.preventDefault();
            }
          }}
        />
        <Form.Control.Feedback type="invalid">
          {isEmailExisting ? (
            <>
              อีเมลนี้มีบัญชีสมาชิกอยู่แล้ว คุณสามารถ
              <span id="redirect-to-sign-in" onClick={() => onClickLogin(prefix)}>
                เข้าสู่ระบบ
              </span>{' '}
              {emailSource === '' ? '' : `ด้วย ${emailSource}`} ได้ทันที
            </>
          ) : (
            errors.email
          )}
        </Form.Control.Feedback>
      </Form.Group>
    </>
  );
};

export const RegisterFormFieldPassword: FunctionComponent<RegisterFormFieldProps> = (props) => {
  const { errors, values, handleChange, handleBlur } = props;
  const [showPassword, setShowPassword] = useState<boolean>(false);
  return (
    <>
      <Form.Group className="position-relative">
        <Form.Control
          id="sign-up-password-input"
          type={showPassword ? 'text' : 'password'}
          placeholder="รหัสผ่าน"
          name="password"
          value={values.password}
          onChange={handleChange}
          isInvalid={!!errors.password}
          onBlur={handleBlur}
          autoComplete="off"
          onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
            const code = e.which ? e.which : e.keyCode;
            if (code == 32) {
              e.preventDefault();
            }
          }}
        />
        <i className={showPassword ? 'icon-eye' : 'icon-eye-off'} onClick={() => setShowPassword(!showPassword)} />
        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
        <Form.Text className={`text-muted ${errors.password ? 'd-none' : ''}`}>
          กรุณาตั้งรหัสผ่านที่มีอักขระ 8 ตัวขึ้นไป โดยใช้ตัวอักษร หรือตัวเลข
        </Form.Text>
      </Form.Group>
    </>
  );
};
