import { PureComponent, useState, useEffect, FunctionComponent } from 'react';
import { navigate } from 'gatsby';
import { withTrans } from '@shares/locales/hoc';
import Button from 'react-bootstrap/Button';
import { withFormik, InjectedFormikProps } from 'formik';
import ResetFormField, { resetSchema, ResetFormValues } from '@pages/auth/components/reset-form-field';
import { authResetPassword, authValidate } from '@ui/components/context/auth';
import { parse } from 'query-string';
import Col from 'react-bootstrap/Col';
import { AlertBox, AlertType } from '@shares/alert-box';
import LoadingBox from '@shares/loading';
import './style.scss';

interface SectionResetProps {
  prefix: string;
}

// interface SectionResetState {}

class SectionReset extends PureComponent<
  InjectedFormikProps<SectionResetProps & TranslationProps, ResetFormValues>,
  {
    isFormAlert: boolean;
    formAlertType?: AlertType;
    alertMassage?: string;
    isValidate: boolean;
  }
> {
  state = {
    isFormAlert: false,
    formAlertType: undefined,
    alertMassage: undefined,
    isValidate: true
  };

  componentDidMount() {
    this.validateResetToken();
  }

  componentDidUpdate(prevProps: InjectedFormikProps<SectionResetProps & TranslationProps, ResetFormValues>) {
    if (prevProps.status !== this.props.status) {
      if (this.props.status.error) this.toggleAlertBox(AlertType.Error, this.props.status.massage);
    }
  }

  validateResetToken = () => {
    authValidate('reset_password_token', this.props.initialValues.reset_password_token, this.props.initialValues.email)
      .then(({ valid }) => {
        if (!valid) {
          return navigate(`/${this.props.prefix}/user/forgot#token-error`);
        } else {
          return this.setState({
            isValidate: false
          });
        }
      })
      .catch(() => navigate(`/${this.props.prefix}/user/forgot#token-error`));
  };
  toggleAlertBox = (errorType: AlertType, massage?: string) => {
    this.setState({ isFormAlert: true, formAlertType: errorType, alertMassage: massage }, () => {
      setTimeout(() => this.setState({ isFormAlert: false }), 4000);
    });
  };

  render() {
    return (
      <Col lg={4} className="d-flex flex-column p-0">
        <AlertBox
          visible={this.state.isFormAlert}
          type={this.state.formAlertType}
          customText={this.state.alertMassage}
        />
        <div className="forgot-box forgot-box--reset p-3 pt-lg-4">
          {this.state.isValidate ? (
            <LoadingBox />
          ) : this.props.status === 'success' ? (
            <div className="success-box d-flex px-4">
              <img src="/assets/shares/reset-done.svg" style={{ width: 120, marginBottom: '1rem' }} />
              <h3>ตั้งรหัสผ่านใหม่สำเร็จ!</h3>
              <CountDownBtn />
            </div>
          ) : (
            <>
              <h3>ตั้งรหัสผ่านใหม่</h3>
              <ResetFormField
                isError={this.state.isFormAlert}
                errors={this.props.errors}
                values={this.props.values}
                handleChange={this.props.handleChange}
                handleBlur={this.props.handleBlur}
                handleSubmit={this.props.handleSubmit}
                isSubmitting={this.props.isSubmitting}
              />
            </>
          )}
        </div>
      </Col>
    );
  }
}

const CountDownBtn: FunctionComponent = () => {
  const [time, setTime] = useState<number>(3);
  const countDown = setInterval(() => {
    setTime(time - 1);
  }, 1000);

  useEffect(() => {
    if (time === 0) {
      clearInterval(countDown);
      navigate('/');
    }
    return () => clearInterval(countDown);
  });
  return (
    <Button
      id="set-new-password-success-redirect"
      type="submit"
      block
      onClick={() => {
        clearInterval(countDown);
        navigate('/');
      }}
    >
      กำลังไปยังหน้าเข้าสู่ระบบ ({time}s)
    </Button>
  );
};

const getDefaultParameters = () => {
  const param: {
    email: any;
    reset_password_token: any;
  } = {
    email: '',
    reset_password_token: ''
  };

  // Start : To prevent SSR when call window
  const canUseDOM = !!(typeof window !== 'undefined' && window.document && window.document.createElement);
  if (canUseDOM) {
    param['email'] = parse(window.location.search).email;
    param['reset_password_token'] = parse(window.location.search).reset_password_token;
  }
  // Stop : To prevent SSR when call window

  return param;
};

const ResetForm = withFormik<SectionResetProps, ResetFormValues>({
  mapPropsToValues: () => {
    const param = getDefaultParameters();
    return {
      password: '',
      email: param.email,
      reset_password_token: param.reset_password_token
    };
  },
  validationSchema: resetSchema(),
  handleSubmit: (values, formikBag) => {
    authResetPassword(values.email, values.password, values.reset_password_token)
      .then(() => {
        formikBag.setStatus('success');
      })
      .catch((e) => {
        console.log(e.data);
        formikBag.setSubmitting(false);
        if (e.status === 400)
          return formikBag.setStatus({
            error: 400,
            massage: 'ลิงก์รีเซ็ตรหัสผ่านของคุณหมดอายุ หรือมีการใช้งานไปแล้ว'
          });
        formikBag.setStatus({ error: e.status });
      });
  }
})(withTrans('authentication.reset')(SectionReset));

export default ResetForm;
